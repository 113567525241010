import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ListNewestDocumentComponent } from './list-newest-document/list-newest-document.component';
import { PrivateFolderComponent } from './private-folder/private-folder.component';
import { SystemFolderComponent } from './system-folder/system-folder.component';
import { DepartmentFolderComponent } from './department-folder/department-folder.component';
import { AuthGuard } from '../core/guard/auth-guard';
import { ShareWithMeComponent } from './share-with-me/share-with-me.component';
import { RecentAccessComponent } from './recent-access/recent-access.component';
import { FavoriteFolderComponent } from './favorite-folder/favorite-folder.component';
import { ArchivesAddComponent } from './archives-add/archives-add.component';
import { ArchivesEditComponent } from './archives-edit/archives-edit.component';
import { ArchivesReadComponent } from './archives-read/archives-read.component';
import { FolderAddComponent } from './folder-add/folder-add.component';
import { FolderEditComponent } from './folder-edit/folder-edit.component';
import { DocumentAddComponent } from './document-add/document-add.component';
import { DocumentEditComponent } from './document-edit/document-edit.component';
import { DocumentReadComponent } from './document-read/document-read.component';
import { FavoriteAddComponent } from './favorite-add/favorite-add.component';
import { DocumentPreviewComponent } from './document-preview/document-preview.component';
import { PublicFolderComponent } from './public-folder/public-folder.component';
import { BaseFolderComponent } from './base-folder/base-folder.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { DocumentViewComponent } from './document-view/document-view.component';
import { FolderAdd2Component } from './folder-add2/folder-add2.component';
import { FolderEdit2Component } from './folder-edit2/folder-edit2.component';
import { DocumentAdd2Component } from './document-add2/document-add2.component';
import { DocumentEdit2Component } from './document-edit2/document-edit2.component';
import { ArchivesAdd2Component } from './archives-add2/archives-add2.component';
import { ArchivesEdit2Component } from './archives-edit2/archives-edit2.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: 'list-newest-document', component: ListNewestDocumentComponent,
      }, {
        path: 'private-folder', component: PrivateFolderComponent,
        canActivate: [AuthGuard], data: { type: ['ASUS'] }
      }, {
        path: 'department-folder', component: DepartmentFolderComponent,
        canActivate: [AuthGuard], data: { type: ['ASUS'] }
      }, {
        path: 'public-folder', component: PublicFolderComponent,
        canActivate: [AuthGuard], data: { type: ['ASUS'] }
      }, {
        path: 'system-folder', component: SystemFolderComponent,
      }, {
        path: 'recent-access', component: RecentAccessComponent,
        canActivate: [AuthGuard], data: { type: ['ASUS'] }
      }, {
        path: 'share-with-me', component: ShareWithMeComponent,
      }, {
        path: 'favorite-folder', component: FavoriteFolderComponent,
        canActivate: [AuthGuard], data: { type: ['ASUS'] }
      }, {
        path: 'document-add', component: DocumentAdd2Component,
      }, {
        path: 'document-edit', component: DocumentEdit2Component,
      }, {
        path: 'document-read', component: DocumentReadComponent,
      }, {
        path: 'archives-add', component: ArchivesAdd2Component,
      }, {
        path: 'archives-edit', component: ArchivesEdit2Component,
      }, {
        path: 'archives-read', component: ArchivesReadComponent,
      }, {
        path: 'folder-add', component: FolderAdd2Component,
      }, {
        path: 'folder-edit', component: FolderEdit2Component,
      },
      {
        path: 'search-result', component: SearchResultComponent,
      },
    ])
  ],
  exports: [
    DocumentViewComponent,
    BaseFolderComponent,
  ],
  declarations: [
    ListNewestDocumentComponent,
    PrivateFolderComponent,
    SystemFolderComponent,
    DepartmentFolderComponent,
    ShareWithMeComponent,
    RecentAccessComponent,
    FavoriteFolderComponent,
    ArchivesAddComponent,
    ArchivesEditComponent,
    ArchivesReadComponent,
    FolderAddComponent,
    FolderEditComponent,
    DocumentAddComponent,
    DocumentEditComponent,
    DocumentReadComponent,
    FavoriteAddComponent,
    DocumentPreviewComponent,
    PublicFolderComponent,
    BaseFolderComponent,
    SearchResultComponent,
    DocumentViewComponent,
    FolderAdd2Component,
    FolderEdit2Component,
    DocumentAdd2Component,
    DocumentEdit2Component,
    ArchivesAdd2Component,
    ArchivesEdit2Component,
  ]
})

export class FolderModule { }
