<div class="popbg-fill show-select-account-permission va-center">
  <div class="pop-box pop-size-block">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" (click)="closeSelectAccount()"><i class="mdi mdi-close"></i></button>
        <h4>{{ "Select User or Group" | translate }}</h4>
      </div>
      <div class="modal-body pad10-t">
        <ul class="step-vertical-wrap pad30-rl pad30-t">
          <li class="active">
            <div class="hgroup">
              <h6 *ngIf="!isDept">選擇人員/群組</h6>
              <h6 *ngIf="isDept">選擇部門</h6>
            </div>
            <div class="pad30-tb pad50-l">
              <div class="form-inline" *ngIf="!isDept">
                <dl class="form-v marg30-r">
                  <dt>
                    <label for="acc_sysName">{{ "AccounSource" | translate }}</label>
                  </dt>
                  <dd>
                    <select class="form-control marg10-r" name="sysName" id='acc_sysName' [(ngModel)]="sysName">
                      <option value="ASUS">ASUS</option>
                      <option value="SCM">SCM</option>
                    </select>
                  </dd>
                  <dt class="marg10-r">
                    <label for="acc_queryName">{{ "Name1" | translate }}/Work ID/{{ "Group" | translate }}</label>
                    <label for="acc_queryName"
                      *ngIf="folderType === 'PUB' && sysName ==='ASUS'">{{ "Department Name" | translate }}/{{ "Department" | translate }}
                      ID/MailGroup</label>
                    <!-- <select class="form-control" [(ngModel)]="permissionName">
                      <option value="UserGroup">姓名 / Work ID / 群組名稱</option>
                      <option value="Dept">部門名稱 / 部門ID / mailGroup</option>
                    </select> -->
                  </dt>
                  <dd class="marg10-r">
                    <input class="form-control" placeholder="{{ 'Query at least 2 letters' | translate }}" id='acc_queryName' name="queryName"
                      [(ngModel)]="queryName">
                  </dd>
                  <dt class="marg10-l" *ngIf="sysName === 'SCM'">
                    <label for="acc_isExternal">{{ "External Account" | translate }}</label>
                  </dt>
                  <dd *ngIf="sysName === 'SCM'">
                    <input type="checkbox" [checked]="isExternal" (change)="isExternal = !isExternal" id='acc_isExternal' name="isExternal">
                  </dd>
                </dl>
                <a class="btn btn-primary marg10-r" href="javascript:void(0)" (click)="query(queryName)">
                  <i class="mdi mdi-magnify"></i>{{ "Search" | translate }}</a>
              </div>
              <div class="form-inline" *ngIf="isDept">
                <dl class="form-v marg30-r">
                  <dt>
                    <label for="acc_queryName">{{ "Department Name" | translate }}/Dept Code</label>
                  </dt>
                  <dd>
                    <input class="form-control" placeholder="{{ 'Query at least 2 letters' | translate }}" id='acc_queryName' name="queryName"
                      [(ngModel)]="queryDeptName">
                  </dd>
                </dl>
                <a class="btn btn-primary marg10-r" href="javascript:void(0)" (click)="queryDeptsOnly(queryDeptName)">
                  <i class="mdi mdi-magnify"></i>{{ "Search" | translate }}</a>
              </div>
              <hr>
              <dl class="selected select-box">
                <dt>
                  <p>{{ "Search Result" | translate }}</p>
                  <ul class="slect-list select-list-checkbox">
                    <li *ngFor="let item of queryDepts" [ngClass]="{ 'readonly': item.disabled }">
                      <label>
                        <input type="checkbox" [checked]="item.checked" (change)="item.checked = !item.checked" [disabled]="item.disabled">
                        <a class="mdi mdi-account-alert circle show-pop" href="javascript:void(0)"
                          (click)="getDeptUsers(item.deptId,item.name,showAccountList)"></a>{{item.name}}
                      </label>
                    </li>
                    <li *ngFor="let item of queryGroups" [ngClass]="{ 'readonly': item.disabled }">
                      <label>
                        <input type="checkbox" [checked]="item.checked" (change)="item.checked = !item.checked" [disabled]="item.disabled">
                        <a class="mdi mdi-account-alert circle show-pop" href="javascript:void(0)"
                          (click)="getGroupUsers(item.groupId,item.name,showAccountList)"></a>{{item.name}}
                      </label>
                    </li>
                    <li *ngFor="let item of queryUsers" [ngClass]="{ 'readonly': item.disabled }">
                      <label>
                        <input type="checkbox" [checked]="item.checked" (change)="item.checked = !item.checked" [disabled]="item.disabled">{{item.name}}
                      </label>
                    </li>
                  </ul>
                </dt>
                <dd>
                  <a class="btn btn-info" href="javascript:void(0)" (click)="addAcc()">
                    <i class="mdi mdi-chevron-double-right"></i>ADD</a>
                  <a class="btn btn-light" href="javascript:void(0)" (click)="delAcc()">
                    <i class="mdi mdi-chevron-double-left"></i>DEL </a>
                </dd>
                <dt>
                  <p>{{ "Selected User or Group" | translate }}</p>
                  <ul class="slect-list select-list-checkbox">
                    <li *ngFor="let item of pickDepts">
                      <label>
                        <input type="checkbox" [checked]="item.checked" (change)="item.checked = !item.checked">
                        <a class="mdi mdi-account-alert circle show-pop" href="javascript:void(0)"
                          (click)="getDeptUsers(item.deptId,item.name,showAccountList)"></a>{{item.name}}
                      </label>
                    </li>
                    <li *ngFor="let item of pickGroups">
                      <label>
                        <input type="checkbox" [checked]="item.checked" (change)="item.checked = !item.checked">
                        <a class="mdi mdi-account-alert circle show-pop" href="javascript:void(0)"
                          (click)="getGroupUsers(item.groupId,item.name,showAccountList)"></a>{{item.name}}
                      </label>
                    </li>
                    <li *ngFor="let item of pickUsers">
                      <label>
                        <input type="checkbox" [checked]="item.checked" (change)="item.checked = !item.checked">{{item.name}}
                      </label>
                    </li>
                  </ul>
                </dt>
              </dl>
              <p class="field-validation-error" *ngIf="showAccountValidation">請至少選擇一個
                <ng-container *ngIf="!isDept">人員/群組 </ng-container>
                <ng-container *ngIf="isDept">部門</ng-container>
              </p>
            </div>
          </li>
          <li class="active">
            <div class="hgroup">
              <h6>選擇權限</h6>
            </div>
            <div class="pad30-tb pad50-l">
              <doc-permission-edit [needCascade]="needCascade" [needReadAll]="needReadAll" [i18nPrefix]="i18nPrefix" [(showParent)]="showParent"
                [fixShowParent]="false" (valueChange)="requirePermission()" [readOperation]="readOperation" [readAllOperation]="readAllOperation"
                [writeOperation]="writeOperation" [adminOperation]="adminOperation"></doc-permission-edit>
              <p class="field-validation-error" *ngIf="showPermissionValidation">請至少選擇一個權限</p>
            </div>
          </li>
          <li class="active">
            <div class="hgroup">
              <h6>完成</h6>
            </div>
            <div class="pad30-tb pad50-l">
              <div class="center pad10-tb">
                <a class="btn btn-sm btn-dark close-btn marg6-r" href="javascript:void(0)" (click)="closeSelectAccount()">
                  <i class=" mdi mdi-reply "></i>{{ "Cancel" | translate }}</a>
                <a class="btn btn-sm btn-primary" href="javascript:void(0)" (click)="saveSelectAccount()">
                  <i class="mdi mdi-check "></i>{{ "Confirm" | translate }}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div #showAccountList class="popbg-fill va-center show-account-list ">
  <div class="pop-box pop-size-600 ">
    <div class="modal-content ">
      <div class="modal-header ">
        <button class="close " (click)="closeAaccountList() ">
          <i class="mdi mdi-close "></i>
        </button>
        <h4>{{ "Group List" | translate }}</h4>
      </div>
      <div class="modal-body ">
        <p>{{showName}} {{ "User List" | translate }}</p>
        <table class="table table-striped table-hover ">
          <tbody>
            <tr>
              <th>Account</th>
              <th>E-mail</th>
              <th>User Name</th>
            </tr>
            <tr *ngFor="let item of showUsers ">
              <td>{{item.userName}}</td>
              <td>{{item.email}}</td>
              <td>{{item.userEngName}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
