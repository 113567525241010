<ul class="breadcrumbs">
    <ng-container *ngIf="viewType ==='system'" >
        <li *ngIf="!(hideHeader==='true')" ><a [routerLink]="['/home']">{{ "Homepage" | translate }}</a></li>
        <li *ngIf="!(hideHeader==='true')" > <a [routerLink]="[getRouterMap(), {data:dataEncode( {folderId:0,deptId:item?.deptId})}]">{{folderTypeName | translate}}</a></li>
        <li *ngFor="let item of folderHierarchy"> <a [routerLink]="[getRouterMap(), {data:dataEncode( {folderId:item.folder_id,deptId:item?.deptId})}]">{{item.folder_name}}</a></li>
    </ng-container>
    <ng-container *ngIf="viewType ==='content'" >
        <li *ngFor="let item of folderHierarchy"> <a [routerLink]="['/system', {data:dataEncode( {folderId:item.folder_id,deptId:item?.deptId})}]">{{item.folder_name}}</a></li>
    </ng-container>
</ul>
